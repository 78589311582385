import { PRODUCTS_SUCCESS,PRODUCTS_FAIL,RESET_PRODUCTS} from "../types/product";

const initialState = {products:[]};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PRODUCTS_SUCCESS:
      return {products:payload};

    case PRODUCTS_FAIL:
    return state;

    case RESET_PRODUCTS:
    return initialState;

    default:
      return state;
  }
}
