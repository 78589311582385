import {
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGOUT
} from "../types/admin";
import {
  SET_MESSAGE,
  SHOW_ERROR,
  SET_LOADING,
  STOP_LOADING
} from "../types/misc";
import axios from "axios";
import {logout} from "./userActions";
export const signIn=(email,password)=>async(dispatch)=>{

  dispatch({type: SET_LOADING});
  try {
    const resp=await axios.post(`/admin/signin`,{email,password});
        dispatch({type:STOP_LOADING});
    if(resp.data.error){
      dispatch({type: ADMIN_LOGIN_FAIL});
      dispatch({type:SHOW_ERROR,payload:resp.data.error});
    }else{
      await localStorage.removeItem('cart');
      await localStorage.removeItem('wishlist');

      await localStorage.setItem("admin", JSON.stringify(resp.data));

       await dispatch({type:ADMIN_LOGIN_SUCCESS,payload:resp.data});
       await dispatch({type:SET_MESSAGE,payload:resp.data.message});
    }
  }catch(error){
    console.log(error)
  }
}

export const adminlogout=()=>async(dispatch)=>{
  try{
    await localStorage.removeItem('admin');
    //await localStorage.removeItem('redux_localstorage_simple');
    dispatch({type:ADMIN_LOGOUT});
  }catch(e){
    console.log(e);
  }
}
