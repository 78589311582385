import {
  DELETE_SUCCESS,
  CART_SUCCESS,
} from "../types/cart";
import {
  SET_MESSAGE,
  SHOW_ERROR,
  SET_LOADING,
  STOP_LOADING
} from "../types/misc";
import axios from "axios";

export const getCart=()=>async(dispatch)=>{
  const user = JSON.parse(localStorage.getItem("user"));
  const cart = JSON.parse(localStorage.getItem("cart"));
  dispatch({type:SET_LOADING});
  try{
    if(user){
      const resp=await axios.get(`/user/cart/${user.user._id}`,{ headers: {"Authorization" : `Bearer ${user.token}`} });
      dispatch({type:STOP_LOADING});
      if(resp.data.error){
        console.log(resp.data.error)
        dispatch({type:SHOW_ERROR,payload:resp.data.error})
      }else{
        //console.log(resp.data.cart.items)
        dispatch({type:CART_SUCCESS,payload:resp.data.cart.items});
        //dispatch({type:SET_MESSAGE,payload:resp.data.message});
      }
    }else{

      await dispatch({type:STOP_LOADING});
      const cart = JSON.parse(localStorage.getItem("cart"));
      let data=[];
      for(let i=0;i<cart.length;i++){
        const id=cart[i].product;
        const resp=await axios.get(`/product/single/${id}`);
        if(resp.data.product){
          await data.push({
            product:resp.data.product,
            quantity:cart[i].quantity
          })
        }

      }
      Promise.all(data).then(items=>{
      //console.log(items);
      dispatch({type:CART_SUCCESS,payload:items});
      }).catch(e=>console.log(e))





    }
  }catch(error){
    console.log(error)
  }
}

export const addItemToCart=(product,quantity,addToast)=>async(dispatch)=>{
    const user = JSON.parse(localStorage.getItem("user"));

  dispatch({type:SET_LOADING});
  try{
    if(user){
      const resp=await axios.post(`/user/cart/addtoCart`,{user:user.user._id,item:{product:product._id,quantity}},{ headers: {"Authorization" : `Bearer ${user.token}`} });
      dispatch({type:STOP_LOADING});
      if(resp.data.error){

        dispatch({type:SHOW_ERROR,payload:resp.data.error})
      }else{
        addToast("Added To Cart", { appearance: "success", autoDismiss: true });
        dispatch({type:SET_MESSAGE,payload:resp.data.message});
      }
    }else{
      const cart = JSON.parse(localStorage.getItem("cart"));
      addToast("Added To Cart", { appearance: "success", autoDismiss: true });
      if(!cart){
        const newCart=[];
        newCart.push({product:product._id,quantity:quantity});
        localStorage.setItem('cart',JSON.stringify(newCart));
      }else{
        const updateCart=JSON.parse(localStorage.getItem("cart"));
        updateCart.push({product:product._id,quantity:quantity});
        localStorage.setItem('cart',JSON.stringify(updateCart));
      }


    }
  }catch(error){
    console.log(error);
  }
}

export const changeQuantity=(productId,type,addToast)=>async(dispatch)=>{
  const user = JSON.parse(localStorage.getItem("user"));

  dispatch({type:SET_LOADING});
  try{
    if(user){
      const resp=await axios.post(`/user/cart/quantity`,{user:user.user._id,productId,type},{ headers: {"Authorization" : `Bearer ${user.token}`} });
      dispatch({type:STOP_LOADING});
      if(resp.data.error){


        dispatch({type:SHOW_ERROR,payload:resp.data.error})
      }else{
        addToast("Changed quantity", { appearance: "success", autoDismiss: true });

        dispatch({type:SET_MESSAGE,payload:resp.data.message});
      }
    }else{
      addToast("Changed quantity", { appearance: "success", autoDismiss: true });
      const items=JSON.parse(localStorage.getItem("cart"));
      const item=items.find(i=>i.product===productId);
      if(type==="increase"){
        item.quantity=item.quantity+1;
      }else{
        item.quantity=item.quantity-1;
      }
        localStorage.setItem('cart', JSON.stringify(items));
    }
  }catch(error){
    console.log(error);
  }
}

export const removeItem=(productId,addToast)=>async(dispatch)=>{

  const user = JSON.parse(localStorage.getItem("user"));
  dispatch({type:SET_LOADING});
  try{
    if(user){
      const resp=await axios.post(`/user/cart/removeItem`,{user:user.user._id,productId},{ headers: {"Authorization" : `Bearer ${user.token}`} });
      //console.log(resp);
      dispatch({type:STOP_LOADING});
      if(resp.data.error){


        dispatch({type:SHOW_ERROR,payload:resp.data.error})
      }else{
        addToast("Removed item successfully", { appearance: "success", autoDismiss: true });
        dispatch({type:SET_MESSAGE,payload:resp.data.message});
      }
    }else{
      addToast("Removed item successfully", { appearance: "success", autoDismiss: true });
      const existing = JSON.parse(localStorage.getItem("cart"));
        let updatedItems=existing.filter(item=>item.product!==productId);
        //console.log(updatedItems);
        localStorage.setItem('cart', JSON.stringify(updatedItems));
    }
  }catch(error){
    console.log(error);
  }
}

export const deleteCart=(addToast)=>async(dispatch)=>{
  const user = JSON.parse(localStorage.getItem("user"));

  dispatch({type:SET_LOADING});
  try{
    if(user){
      const resp=await axios.post(`/user/cart/deleteCart`,{user:user.user._id},{ headers: {"Authorization" : `Bearer ${user.token}`} });
      //console.log(resp);
      dispatch({type:STOP_LOADING});
      if(resp.data.error){
            console.log(resp.data.error);

      }else{
        dispatch({type:DELETE_SUCCESS});
        addToast("Deleted cart successfully", { appearance: "success", autoDismiss: true });
        dispatch({type:SET_MESSAGE,payload:resp.data.message});

      }
    }else{
    await localStorage.removeItem('cart')
      await dispatch({type:DELETE_SUCCESS});
      await addToast("Deleted cart successfully", { appearance: "success", autoDismiss: true });


    }
  }catch(error){
    console.log(error);
  }
}
