import {
  ADD_CART_SUCCESS,
  ADD_CART_FAIL,
  QUANTITY_SUCCESS,
  QUANTITY_FAIL,
  REMOVE_SUCCESS,
  REMOVE_FAIL,
  DELETE_SUCCESS,
  DELETE_FAIL,
  CART_SUCCESS,
  CART_FAIL
} from "../types/cart";

const initialState=[];

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_CART_SUCCESS:
      return state
    case ADD_CART_FAIL:
      return state;
    case QUANTITY_SUCCESS:
      return payload
    case QUANTITY_FAIL:
      return state;
    case REMOVE_SUCCESS:
      return payload
    case REMOVE_FAIL:
      return state;
    case DELETE_SUCCESS:
      return initialState;
    case DELETE_FAIL:
      return state;
    case CART_SUCCESS:
      //console.log("payload",payload)
      return payload
    case CART_FAIL:
      return state;
    default:
      return state;
  }
}
