import {
  ADD_WISHLIST_SUCCESS,
  ADD_WISHLIST_FAIL,
  REMOVE_SUCCESS,
  REMOVE_FAIL,
  DELETE_SUCCESS,
  DELETE_FAIL,
  WISHLIST_SUCCESS,
  WISHLIST_FAIL
} from "../types/wishlist";

const initialState=[];

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_WISHLIST_SUCCESS:
      return state
    case ADD_WISHLIST_FAIL:
      return state;
    case REMOVE_SUCCESS:
      return payload
    case REMOVE_FAIL:
      return state;
    case DELETE_SUCCESS:
      return initialState;
    case DELETE_FAIL:
      return state;
    case WISHLIST_SUCCESS:
      return payload
    case WISHLIST_FAIL:
      return state;
    default:
      return state;
  }
}
