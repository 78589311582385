import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import AdminRoute from "./utils/AdminRoutes";
import UserRoute from "./utils/UserRoutes";
import jwt from "jsonwebtoken";
import { adminlogout } from "./redux/actions/adminAuthAction";
import axios from "axios";
// home page
const HomeFurnitureFour = lazy(() => import("./pages/home"));

// shop pages
const ShopListStandard = lazy(() => import("./pages/shop"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));

// blog pages
const BlogDetailsStandard = lazy(() => import("./pages/blog"));
const PrevBlog = lazy(() => import("./pages/blog/prevblog"));
const NextBlog = lazy(() => import("./pages/blog/nextblog"));
const BlogStandard = lazy(() => import("./pages/blog/main"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const Return = lazy(() => import("./pages/other/Return"));
const Shipping = lazy(() => import("./pages/other/Shipping"));
const Privacy = lazy(() => import("./pages/other/Privacy"));
const TnC = lazy(() => import("./pages/other/TnC"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const VerifyEmail = lazy(() => import("./pages/other/VerifyEmail"));
const ResetPassword = lazy(() => import("./pages/other/ResetPassword"));
const NotFound = lazy(() => import("./pages/other/NotFound"));

// Admin components
const Admin = lazy(() => import("./admin/"));
const AdminLogin = lazy(() => import("./admin/pages/Login"));
const AdminHome = lazy(() => import("./admin/pages/Home"));
const AdminInventory = lazy(() => import("./admin/pages/Inventory"));
const AdminForms = lazy(() => import("./admin/pages/Forms"));
const AdminTestimonial = lazy(() => import("./admin/pages/Testimonial"));
const UpdateForm = lazy(() => import("./admin/pages/UpdateForm"));
const UpdateTestimonial = lazy(() => import("./admin/pages/UpdateTestimonial"));
const Users = lazy(() => import("./admin/pages/Users"));
const Orders = lazy(() => import("./admin/pages/Orders"));
const Banner = lazy(() => import("./admin/pages/Banner"));

// axios.defaults.baseURL =
//   "https://sports-ami.onrender.com" + "/api" ||
//   "https://www.wearetabletennis.com";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL + "api";

const App = (props) => {
  /*  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
          en: require('./translations/english.json'),
          fn: require('./translations/french.json'),
          de: require('./translations/germany.json'),
        },
      })
    );
  });*/

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="SportsAmi-preloader-wrapper">
                  <div className="SportsAmi-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                {/* Homepage */}
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/"}
                  component={HomeFurnitureFour}
                />

                {/* Shop page */}
                <Route
                  path={process.env.PUBLIC_URL + "/shop"}
                  component={ShopListStandard}
                />

                {/* Shop product page */}
                <Route
                  path={process.env.PUBLIC_URL + "/product/:id"}
                  component={Product}
                />

                {/* Blog page */}
                <Route
                  path={process.env.PUBLIC_URL + "/blog-view"}
                  component={BlogDetailsStandard}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog"}
                  component={BlogStandard}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-view-1"}
                  component={PrevBlog}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-view-3"}
                  component={NextBlog}
                />

                {/* Other pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/about"}
                  component={About}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/contact"}
                  component={Contact}
                />
                <UserRoute
                  path={process.env.PUBLIC_URL + "/my-account"}
                  component={MyAccount}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/login-register"}
                  render={(props) => (
                    <LoginRegister {...props} key={Date.now()} />
                  )}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/cart"}
                  component={Cart}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/wishlist"}
                  component={Wishlist}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/checkout"}
                  component={Checkout}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/return"}
                  component={Return}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shipping"}
                  component={Shipping}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/privacy"}
                  component={Privacy}
                />
                <Route path={process.env.PUBLIC_URL + "/tnc"} component={TnC} />
                <Route
                  path={process.env.PUBLIC_URL + "/verifyEmail/:token"}
                  component={VerifyEmail}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/resetPassword/:token"}
                  component={ResetPassword}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                />

                {/*========== Admin Routes ==========*/}
                {/* Admin Login */}
                <Route
                  path={process.env.PUBLIC_URL + "/admin/login"}
                  component={AdminLogin}
                />

                {/* Admin Dashboard */}

                <AdminRoute
                  exact
                  path={process.env.PUBLIC_URL + "/admin"}
                  component={AdminHome}
                />
                <AdminRoute
                  path="/admin/inventory"
                  component={AdminInventory}
                />

                <AdminRoute
                  path={process.env.PUBLIC_URL + "/admin/forms"}
                  component={AdminForms}
                />
                <AdminRoute
                  path={process.env.PUBLIC_URL + "/admin/testimonial"}
                  component={AdminTestimonial}
                />
                <AdminRoute
                  path={process.env.PUBLIC_URL + "/admin/users"}
                  component={Users}
                />

                <AdminRoute
                  path={process.env.PUBLIC_URL + "/admin/product/update/:id"}
                  component={UpdateForm}
                />

                <AdminRoute
                  path={process.env.PUBLIC_URL + "/admin/update/:id"}
                  component={UpdateTestimonial}
                />
                <AdminRoute
                  path={process.env.PUBLIC_URL + "/admin/orders"}
                  component={Orders}
                />
                <AdminRoute
                  path={process.env.PUBLIC_URL + "/admin/banner"}
                  component={Banner}
                />
                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(multilanguage(App));
