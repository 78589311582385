import { RELATED_SUCCESS,RELATED_FAIL,RESET_RELATED} from "../types/product";

const initialState = {products:[]};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RELATED_SUCCESS:
      return {products:payload};

    case RELATED_FAIL:
    return state;

    case RESET_RELATED:
    return initialState;

    default:
      return state;
  }
}
