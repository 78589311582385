import { SINGLE_FAIL,SINGLE_SUCCESS,RESET} from "../types/product";

const initialState={product:{}}

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SINGLE_SUCCESS:
      return {product:payload};

    case SINGLE_FAIL:
      return state;

    case RESET:
      return initialState;

    default:
      return state;
  }
}
