import { CATEGORIES_SUCCESS,CATEGORIES_FAIL} from "../types/category";

const initialState = {categories:[]};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CATEGORIES_SUCCESS:
      return {categories:payload};

    case CATEGORIES_FAIL:
    return state;

    default:
      return state;
  }
}
