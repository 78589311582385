export const ADD_CART_SUCCESS = "ADD_CART_SUCCESS";
export const ADD_CART_FAIL = "ADD_CART_FAIL";
export const QUANTITY_SUCCESS = "QUANTITY_SUCCESS";
export const QUANTITY_FAIL = "QUANTITY_FAIL";
export const REMOVE_SUCCESS = "REMOVE_SUCCESS";
export const REMOVE_FAIL = "REMOVE_FAIL";
export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const DELETE_FAIL = "DELETE_FAIL";
export const CART_SUCCESS = "CART_SUCCESS";
export const CART_FAIL = "CART_FAIL";
