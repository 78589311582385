import { SET_MESSAGE, SHOW_ERROR, SET_LOADING, STOP_LOADING, CLEAR_MESSAGE, CLEAR_ERROR} from "../types/misc";

const initialState = {loading:false,error:"",message:""};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_MESSAGE:
      return { message: payload,loading: false,error:""};

    case CLEAR_MESSAGE:
    return { message: "",loading: false,error:""};

    case SHOW_ERROR:
      return { error: payload,message:"",loading:false };

    case CLEAR_ERROR:
      return { message: "",loading: false,error:""};

    case SET_LOADING:
      return { loading: true,error:"",message:""};

    case STOP_LOADING:
      return { loading: false,error:"",message:""};

    default:
      return state;
  }
}
