import React,{Component} from "react";
import {Route,Redirect} from "react-router-dom";

const isAuthenticated=()=>{
  const user = JSON.parse(localStorage.getItem("admin"));
  if(user&&user.user.role==="admin"){
    return true;
  }
  return false;
}



const AdminRoute=({component:Component,...rest})=>(
  <Route {...rest} render={props=>isAuthenticated()?(
    <Component {...props}/>
  ):(
    <Redirect to={{pathname:"/admin/login",state:{from:props.location}}}/>
  )}/>
)

export default AdminRoute;
